import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Datetime from "react-datetime";

import {
  SearchIcon,
  EditIcon,
  UserAddIcon,
  DownloadIcon,
  CalendarIcon,
  AddIcon,
  DropDownIcon,
  DropUpIcon,
} from "../../assets";

import { useGetUsersListQuery } from "../../store/services/usersService";
import { useCreatePayrollMutation } from "../../store/services/payrollService";
import Loader from "../../components/Loader";

const NewPayroll = () => {
  const navigate = useNavigate();
  const {
    data: usersList,
    isFetching: isFetchingUsersList,
    isLoading: isLoadingUsersList,
  } = useGetUsersListQuery();

  const [loading, setLoading] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [date, setDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [payDate, setPayDate] = useState(new Date());
  const [userDataSet, setUserListDataSet] = useState(null);
  const [checkedState, setCheckedState] = useState([]);
  const [checkedCPF, setCheckedCPF] = useState({});
  const [allCheckState, setAllCheckedState] = useState(false);

  var month = date.getUTCMonth() + 1;
  var day = date.getUTCDate();
  var year = date.getUTCFullYear();

  const [createNewPayrollUsers, setCreateNewPayrollUsers] = useState({
    label: {
      year: year,
      month: month,
      name: "",
    },
    workPeriod: {
      from: fromDate,
      to: toDate,
    },
    payDate: payDate,
    users_id: [],
  });

  const hangleLabelDateChange = (e) => {
    const newChangedDate = new Date(e);
    setDate(newChangedDate)
    setCreateNewPayrollUsers((prevState) => ({
      ...prevState,
      label: {
        ...prevState.label,
        year: newChangedDate.getUTCFullYear(),
        month: newChangedDate.getUTCMonth() + 1,
      }
    }));
  };

  const hangleWorkPeriodDateChange = (e, fieldName) => {
    const newChangedDate = new Date(e);
    if (fieldName === "from") {
      setFromDate(newChangedDate);
    }
    if (fieldName === "to") {
      setToDate(newChangedDate);
    }
    setCreateNewPayrollUsers((prevState) => ({
      ...prevState,
      workPeriod: {
        ...prevState.workPeriod,
        [`${fieldName}`]: newChangedDate,
      }
    }));
  };

  const hanglePayDateChange = (e) => {
    const newChangedDate = new Date(e);
    setPayDate(newChangedDate);
    setCreateNewPayrollUsers((prevState) => ({
      ...prevState,
      payDate: newChangedDate
    }));
  };

  useEffect(() => {
    setCreateNewPayrollUsers((prevState) => ({
      ...prevState,
      label: {
        ...prevState.label,
        name: labelName,
      },
    }));
  }, [labelName]);

  
  useEffect(() => {
    if (!isFetchingUsersList) {
      const dataTarget = usersList?.data.filter((dataSet) => {
        return dataSet.roles.find((contentTarget) => {
          return contentTarget === "user";
        });
      });
      setUserListDataSet(dataTarget);
      setCheckedState(new Array(dataTarget.length).fill(false));
      
      const mapToCheckbox = {};
      usersList?.data?.map((user) => {
        mapToCheckbox[user._id] = true;
      })
      setCheckedCPF(mapToCheckbox);
    }
  }, [usersList?.data]);

  const handleCheckBox = (targetIndex) => {
    let updatedCheckedState;
    if (targetIndex === "all") {
      updatedCheckedState = checkedState.map(() =>
        allCheckState === false ? true : false
      );
      setAllCheckedState(prevState => (prevState === false ? true : false))
    } else {
      updatedCheckedState = checkedState.map((item, index) =>
        index === targetIndex ? !item : item
      );
    }
    setCheckedState(updatedCheckedState);
    let selectedUser = [];
    updatedCheckedState.forEach((item, index) => {
      if (item === true) {
        selectedUser.push(userDataSet[index]._id)
      }
    });
    setCreateNewPayrollUsers((prevState) => ({
      ...prevState,
      users_id: selectedUser
    }));
  };

  const handleCheckBoxCPF = (userId) => {
    let updatedCheckedCPF = {...checkedCPF};
    updatedCheckedCPF[userId] = !updatedCheckedCPF[userId];
    setCheckedCPF(updatedCheckedCPF);
  };
  
  const [savePayroll, data] = useCreatePayrollMutation();

  const errors = data?.error?.data ? data?.error?.data.message : null;
  const [errorMessage, setErrorMessage] = useState(null);

  const createNewPayroll = (e) => {
    const selectedCheckboxCPF = createNewPayrollUsers.users_id.map((userId) => {
      return checkedCPF[userId];
    })
    e.preventDefault();
    // setLoading(true);
    if (createNewPayrollUsers?.label?.name === "") {
      setErrorMessage("Please enter Label");
    } else if (createNewPayrollUsers?.users_id?.length === 0) {
      setErrorMessage("Please select any one user");
    } else {
      savePayroll({
        ...createNewPayrollUsers,
        users_id_cpf: selectedCheckboxCPF
      });
    }
  };
  const cancelHandle = () => {
    navigate("/payroll");
  }
  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/payroll");
    }
  }, [data?.isSuccess]);

  return (
    <div className="new-payroll-page flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {errorMessage !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errorMessage}
          </div>
        )}
        {!data?.isLoading ? (
          <>
            <div className="hdr-filters flex flex-col">
              <div className="row">
                <div className="row-field flex flex-col">
                  <div className="page-tag mb-3">Payroll Label</div>
                      
                  <div className="input-field flex aic">
                    <div className="date-picker flex aic jc">
                      <Datetime
                        closeOnSelect={true}
                        value={date ? date : new Date()}
                        onChange={hangleLabelDateChange}
                        timeFormat={false}
                        dateFormat="yyyy-MM"
                        showMonthYearPicker
                        className="start-date cleanbtn"
                      />
                      <div className="ico">
                        <CalendarIcon />
                      </div>
                    </div>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={labelName}
                      onChange={(e) => setLabelName(e.target.value)}
                      className="txt"
                      placeholder="Label"
                    />
                  </div>
                </div>
                
                <div className="row-field flex flex-col">
                  <div className="page-tag mb-3">Work Period</div>
                  <div className="input-field flex aic">
                    <div className="date-field flex aic">
                      <div className="lbl mr-2 s14">From</div>
                      <div className="date-picker flex aic jc">
                        <Datetime
                          closeOnSelect={true}
                          value={fromDate ? fromDate : new Date()}
                          onChange={(value) => {
                            hangleWorkPeriodDateChange(value, "from")
                          }}
                          timeFormat={false}
                          dateFormat="DD-MM-YYYY"
                          className="start-date cleanbtn"
                        />
                        <div className="ico">
                          <CalendarIcon />
                        </div>
                      </div>
                    </div>
                    <div className="date-field flex aic">
                      <div className="lbl ml-6 mr-2 s14">To</div>
                      <div className="date-picker flex aic jc">
                        <Datetime
                          closeOnSelect={true}
                          value={toDate ? toDate : new Date()}
                          onChange={(value) => {
                            hangleWorkPeriodDateChange(value, "to")
                          }}
                          timeFormat={false}
                          dateFormat="DD-MM-YYYY"
                          className="start-date cleanbtn"
                        />
                        <div className="ico">
                          <CalendarIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="row-field flex flex-col">
                  <div className="page-tag mb-3">display as : </div>
                  <div className="input-field flex aic">
                    <h5>{`${createNewPayrollUsers.label.year}_${createNewPayrollUsers.label.month}_${createNewPayrollUsers.label.name}`}</h5>
                  </div>

                </div>
                <div className="row-field flex flex-col">
                  <div className="page-tag mb-3">Pay Date</div>
                  <div className="input-field flex aic">
                    <div className="date-picker flex aic jc">
                      <Datetime
                        closeOnSelect={true}
                        value={payDate ? payDate : new Date()}
                        onChange={hanglePayDateChange}
                        timeFormat={false}
                        dateFormat="DD-MM-YYYY"
                        className="start-date cleanbtn"
                      />
                      <div className="ico">
                        <CalendarIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-list-block flex flex-col">
              <div className="block-hdr flex aic justify-between">
                <div className="tag">Users</div>
                <div className="search-box flex aic">
                  <input
                    type="text"
                    className="txt cleanbtn cursor-pointer"
                    placeholder="Search here"
                  />
                  <div className="icon flex aic jc">
                    <SearchIcon />
                  </div>
                </div>
              </div>

              <div className="summary-table-blk flex">
                <div className="tbl flex flex-col">
                  <div className="row flex aic">
                    <div className="col flex">
                      <div className="col-item flex aic">
                        <input
                          type="checkbox"
                          className="check-box cursor-pointer h-4 w-4"
                          name="allSelect"
                          // checked={
                          //   usersList?.data?.length ===
                          //   createNewPayrollUsers?.users_id?.length
                          // }
                          onChange={(e) => handleCheckBox("all")}
                        />
                        <div className="check-lbl ml-2">Select All</div>
                      </div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Name</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Last Name</div>
                    </div>
                    {/* <div className="col flex">
                      <div className="col-item">Employee ID</div>
                    </div> */}
                    <div className="col flex">
                      <div className="col-item">National ID</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Email</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Department</div>
                    </div>
                    <div className="col flex" style={{width: "120px", flex: "none"}}>
                      <div className="col-item">Enable CPF</div>
                    </div>
                  </div>
                  {!isLoadingUsersList ? (
                    <>
                      {userDataSet?.map((item, index) => (

                        <div key={index} className="row flex aic">
                          <div className="col flex items-start">
                            <input
                              type="checkbox"
                              value={item._id}
                              checked={checkedState[index]}
                              onChange={(e) => handleCheckBox(index)}
                              className="check-box cursor-pointer h-4 w-4"
                            />
                          </div>
                          <div className="col flex">
                            <div className="col-item uppercase">
                              {item.firstName}
                            </div>
                          </div>
                          <div className="col flex">
                            <div className="col-item">{item.lastName}</div>
                          </div>
                          {/* <div className="col flex">
                            <div className="col-item">{item.userId}</div>
                          </div> */}
                          <div className="col flex">
                            <div className="col-item">{item.nationalId}</div>
                          </div>
                          <div className="col flex">
                            <div className="col-item flex">{item.email}</div>
                          </div>
                          <div className="col flex">
                            <div className="col-item flex">
                              {item?.department_id
                                ? item?.department_id?.name
                                : "-"}
                            </div>
                          </div>
                          <div className="col flex" style={{width: "120px", flex: "none"}}>
                            <div className="col-item flex items-start">
                              <input
                                type="checkbox"
                                value={item._id}
                                checked={checkedCPF[item._id]}
                                onChange={(e) => handleCheckBoxCPF(item._id)}
                                className="check-box cursor-pointer h-4 w-4"
                              />
                            </div>
                          </div>
                        </div>

                      ))}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
            <div className="actions-pay flex aic justify-end">
              <div className="btn button" onClick={cancelHandle}>Cancel</div>
              <div
                className="btn button ml-4"
                onClick={(e) => createNewPayroll(e)}
              >
                Create
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default NewPayroll;
