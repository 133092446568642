import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { EditIcon, DelIcon, DelIconPy, EditIconPy, EyeIcon } from "../../assets";
import Modal from "../../components/Modal";
import MyClaimDelete from "../../components/UserSide/MyClaimDelete";

import Loader from "../../components/Loader";
import {
  useGetClaimListQuery,
  useDeleteMyClaimMutation,
} from "../../store/services/claimsService";
import { thousandAndDecimalFormat } from "../../utils/common";
import DataTable from "react-data-table-component";
import {
  useFetchSingleUserQuery,
  useGetUsersListQuery
} from "../../store/services/usersService";
import {
  useFetchUserInfoQuery
} from "../../store/services/generalService"

const UserClaimsList = () => {
  const navigate = useNavigate();
  const { userRole, userToken, userOrgId, userId, userInfo } = useSelector(
    (state) => state.authReducer
  );
  let logedInfo = typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;

  const { data, isLoading, isFetching } = useGetClaimListQuery(
    logedInfo._id
  );
  
  //get supervisor data
  const userdata = useFetchUserInfoQuery(logedInfo.uId);
  const supervisorId = data?.data[0]?.supervisorProfile?.id;
  const supervisorData = userdata?.data?.data?.reportTo.find(item => item._id === supervisorId);      
    
  const [open, setOpen] = useState(false);
  const [myClaimId, setMyClaimId] = useState();

  const getSumTotal = (data) => {
    let result = 0;
    if (data) {
      if (data.length > 0) {
        data.map((element) => {
          result += element?.amount ? element.amount : 0; 
        })
      }
    }
    return result
  }
  
  

  const renderColumns = () => {
    return [
        {
            name: "Request Date",
            sortable: true,
            cell: row => (
              <div>{`${moment(row?.requestDate).format(
                "D"
              )}-${moment(row?.requestDate).format(
                "MM"
              )}-${moment(row?.requestDate).format("YYYY")}`}</div>
            )
        },
        {
            name: "Total Amount",
            sortable: true,
            cell: row => (
              <div>{thousandAndDecimalFormat(getSumTotal(row?.details), true, 2)}</div>
            )
        },
        {
          name: "Supervisor",
          sortable: true,
          cell: row => (
            <div>{supervisorData.firstName}</div>
          )
      },
        {
            name: "Status by Supervisor",
            sortable: true,
            cell: row => (
              <div>
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'approved') && <span className="status-tag success">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'pending') && <span className="status-tag secondary">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'rejected') && <span className="status-tag danger">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'claimed') && <span className="status-tag primary">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
              </div>
            )
        },
        {
            name: "Status by Admin/HR",
            sortable: true,
            cell: row => (
              <div>
                {
                  (String(row.status.byHr).toLowerCase() === 'approved') && <span className="status-tag success">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'pending') && <span className="status-tag secondary">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'rejected') && <span className="status-tag danger">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'claimed') && <span className="status-tag primary">{String(row.status.byHr).toUpperCase()}</span>
                }
              </div>
            )
        },
        {
            name: 'Details',
            button: true,
            cell: row => (
                  <>
                  {(row.status?.byHr === "pending" && row.status?.bySupervisor === "pending" ) ?
                    <>
                      <div
                        onClick={(e) => {navigate(`/update-claims-form/${row?.uId}`)}}
                        className="icon cs-pointer"
                      >
                        <EditIcon status={row.status?.byHr} typePage="payroll" statusTwo={row.status?.bySupervisor} />
                      </div>
                      <div
                        className="icon cs-pointer"
                        onClick={(e) => {
                          setOpen(true);
                          setMyClaimId({ uId: row.uId });
                        }}
                      >
                        <DelIcon status={row.status?.byHr} typePage="payroll" statusTwo={row.status?.bySupervisor}/>
                      </div>
                      </>

                    :
                      <div
                        onClick={(e) => {navigate(`/claims-detail/${row?.uId}`)}}
                        className="icon cs-pointer"
                      >
                        <EyeIcon status={row.status?.byHr} typePage="payroll" statusTwo={row.status?.bySupervisor} />
                      </div>
                    }
                </>
            )
        }
    ];
  };
  
  return (
    <div className="users-claims-list flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex justify-end">
          <Link to="/new-claims-form" className="btn button rounded-lg">
            New
          </Link>
        </div>
        {!isLoading && !isFetching ? (
          <div className="table-blk flex">
            <div className="tbl flex flex-col">
              
              <DataTable
                columns={renderColumns()}
                data={data?.data}
                responsive={true}
                pagination={true} />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <MyClaimDelete setOpen={setOpen} data={myClaimId} />
      </Modal>
    </div>
  );
};

export default UserClaimsList;
